$primary: #54634b;
$secondry: #f2e1ce;

.customiza-tab-container {
  padding-bottom: 80px;
  .left-section {
    background-color: #fff;
    border: 1px solid #ebeae4;
    padding: 34px;
    border-radius: 10px;
    .filter_wrap {
      width: 100%;
      max-width: 100%;
    }
    .plus-btn {
      margin-top: 14px;
    }
    .plus-btn button {
      background-color: #fcf9f5;
      border: 1px solid #ebeae4;
      border-radius: 24px;
      color: #54634b;
      font-size: 16px;
      font-weight: 800;
    }
    .accordion-button:focus {
      box-shadow: none;
    }
    .accordion-button {
      color: #54634b;
      background-color: transparent;
      box-shadow: none;
      font-size: 18px;
      font-weight: 800;
    }
    .accordion-item {
      border-bottom: 1px solid #ebeae4;
    }
    .option-text {
      padding: 0px 20px;
      font-size: 16px;
      color: #54634b;
      font-weight: 400;
    }
  }
  .right-section {
    background-color: #fff;
    border: 1px solid #ebeae4;
    padding: 8px;
    border-radius: 10px;
    .top-link {
      display: flex;
      align-items: center;
      font-size: 14px;
      p {
        margin: 0px;
        padding: 0px;
      }
      a {
        color: #54634b;
      }
    }
    .add-items-text {
      font-size: 24px;
      font-weight: 800;
      color: #54634b;
    }
    .add-items-sub-text {
      font-size: 14px;
      font-weight: 800;
    }
    .filter_wrap {
      width: 100%;
      max-width: 100%;
    }
    .items-main {
      border-bottom: 1px solid;
      border-color: #ebeae4;
      padding-top: 12px;
      padding-bottom: 12px;
      .first-items {
        width: 30%;
      }
    }
    .items {
      width: 40%;
      .title {
        font-size: 18px;
        font-weight: 800;
        color: #54634b;
      }
      p {
        margin: 0px;
      }
      .sub-title {
        font-size: 16px;
        color: #54634b;
        font-weight: 14px;
      }
    }
    .cross-img {
      width: 30%;
      text-align: end;
    }
  }

  /* second right */
  .right-section {
    background-color: #fff;
    border: 1px solid #ebeae4;
    padding: 8px;
    border-radius: 10px;
    height: 100%;
    .customization-edit-section {
      .top-view {
        display: flex;
        justify-content: space-between;
      }
      h4 {
        font-size: 24px;
        font-weight: 900;
        margin-top: 20px;
      }
      .customize-edit {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 14px;
        .left-sections {
          h6 {
            font-size: 16px;
            font-weight: 800;
            margin-bottom: 4px;
          }
          p {
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
      .notes {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 6px solid #ebeae4;
        .left-sections {
          h6 {
            font-size: 16px;
            font-weight: 800;
            margin-bottom: 4px;
          }
          p {
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
      .edit-option-div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px 0px;
        border-bottom: 1px solid #ebeae4;
        .edit-option-heading {
          h6 {
            font-weight: 800;
            font-size: 16px;
          }
          p {
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 0px;
          }
          span {
            font-size: 14px;
            font-weight: 800;
            margin-top: 4px;
          }
        }
      }
    }
    .customize-rules-third-container {
      .cr-heading {
        font-size: 24px;
        font-weight: 900;
        margin-top: 20px;
      }
      .cr-subHeading {
        font-size: 14px;
        font-weight: 400;
        margin-top: 14px;
      }
      .minimum-qua-box {
        margin-bottom: 16px;
        h5 {
          font-size: 16px;
          font-weight: 800;
        }
        .count-section {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 14px;
          .count-pera {
            width: 60%;
            p {
              margin-bottom: 0px;
            }
          }
          .count-btn {
            width: 40%;
            text-align: end;
            button {
              background-color: #f2e1ce;
              border-width: 0px;
              width: 102px;
              padding: 7px 17px;
              text-align: left;
              border-radius: 5px;
              font-weight: 800;
              font-size: 14px;
              color: #54634b;
            }
            .toggle {
              position: relative;
              display: block;
              width: 68px;
              height: 33px;
              padding: 2px;
              margin: 0 0 0 auto;
              border-radius: 50px;
              cursor: pointer;
            }
            .toggle-input {
              position: absolute;
              top: 0;
              left: 0;
              opacity: 0;
            }
            .toggle-label {
              position: relative;
              display: block;
              height: inherit;
              font-size: 12px;
              background: #90a285;
              border-radius: inherit;
              box-shadow:
                inset 0 1px 2px rgba(0, 0, 0, 0.12),
                inset 0 0 3px rgba(0, 0, 0, 0.15);
            }
            .toggle-label:before,
            .toggle-label:after {
              position: absolute;
              top: 50%;
              color: black;
              margin-top: -0.5em;
              line-height: 1;
            }
            .toggle-label:before {
              content: attr(data-off);
              right: 11px;
              color: #fff;
              text-shadow: 0 1px rgba(255, 255, 255, 0.5);
            }
            .toggle-label:after {
              content: attr(data-on);
              left: 11px;
              color: #fff;
              text-shadow: 0 1px rgba(0, 0, 0, 0.2);
              opacity: 0;
            }
            .toggle-input:checked ~ .toggle-label {
              background: #54634b;
            }
            .toggle-input:checked ~ .toggle-label:before {
              opacity: 0;
            }
            .toggle-input:checked ~ .toggle-label:after {
              opacity: 1;
            }
            .toggle-handle {
              position: absolute;
              top: 6px;
              left: 4px;
              width: 25px;
              height: 25px;
              background: linear-gradient(to bottom, #ffffff 40%, #f0f0f0);
              border-radius: 50%;
            }
            .toggle-handle:before {
              position: absolute;
              top: 50%;
              left: 50%;
              margin: -6px 0 0 -6px;
              width: 16px;
              height: 16px;
            }
            .toggle-input:checked ~ .toggle-handle {
              left: 36px;
              box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
            }

            /* Transition*/
            .toggle-label,
            .toggle-handle {
              transition: All 0.3s ease;
              -webkit-transition: All 0.3s ease;
              -moz-transition: All 0.3s ease;
              -o-transition: All 0.3s ease;
            }
          }
        }
      }
      .set-condi-price {
        margin-top: 38px;
        a {
          color: #983939;
          font-size: 14px;
          font-weight: 400;
        }
      }
      .confirm-btn {
        width: 100%;
        margin-top: 100px;
        button {
          width: 100%;
          padding: 11px 0px;
        }
      }
      .plus-btn {
        margin-top: 14px;
      }
      .plus-btn button {
        background-color: #fcf9f5;
        border: 1px solid #ebeae4;
        border-radius: 24px;
        color: #54634b;
        font-size: 16px;
        font-weight: 800;
      }
      .plus-container {
        border-bottom: 2px solid #ebeae4;
        padding-bottom: 11px;
      }

      .delivery-btn {
        background-color: #54634b;
        border-radius: 24px;
        padding: 5px 18px;
        color: #f2e1ce;
        border-width: 0;
        font-size: 14px;
        font-weight: 400;
      }
      .pickup-btn {
        background-color: #fff;
        border: 1px solid #54634b;
        border-radius: 24px;
        padding: 5px 18px;
        color: #54634b;
        margin-left: 10px;
        font-size: 14px;
        font-weight: 400;
      }
      .regular-milk-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0px;
        border-bottom: 1px solid #ebeae4;
        .left-div {
          display: flex;
          align-items: center;
          .right-sub-div {
            padding-left: 30px;
            h6 {
              font-size: 14px;
              font-weight: 900;
            }
            p {
              font-size: 14px;
              font-weight: 800;
              margin-bottom: 0px;
            }
          }
        }
        .right-div {
          display: flex;
          .edit-btn {
            button {
              background-color: #f2e1ce;
              border-radius: 50px;
              padding: 5px 24px;
              border-width: 0;
              color: #54634b;
            }
            margin-right: 8px;
          }
        }
      }
    }
    .edit-option-div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      .edit-left {
        display: flex;
        align-items: center;
        h5 {
          font-weight: 900;
          font-size: 24px;
          margin-bottom: 0px;
          margin-left: 10px;
        }
      }
      .edit-right {
        button {
          background-color: #54634b;
          border-radius: 50px;
          border-width: 0px;
          padding: 5px 25px;
          color: #f2e1ce;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
    .input-container {
      border-bottom: 2px solid #ebeae4;
      padding-bottom: 20px;
    }
    .checkbox-sold {
      display: flex;
      align-items: center;
      input {
        width: 20px;
        height: 20px;
        font-weight: 800;
      }
      label {
        margin-left: 10px;
        font-size: 14px;
        font-weight: 800;
      }
    }
    .price-by-container {
      padding-bottom: 20px;
      border-bottom: 2px solid #ebeae4;
      .price-div {
        margin-top: 16px;
        h6 {
          font-weight: 900;
          font-size: 16px;
        }
        .each-btn {
          background-color: #f2e1ce;
          border-radius: 24px;
          border: 1px solid #54634b;
          padding: 5px 24px;
          color: #54634b;
          font-size: 14px;
          font-weight: 400;
        }
      }
      .sold-by-delivery-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 19px;
        p {
          margin-bottom: 0px;
          font-size: 14px;
          font-weight: 800;
        }
        .r-none-btn {
          background-color: #f2e1ce;
          padding: 6px 16px;
          border-radius: 5px;
          border-width: 0;
          color: #54634b;
        }
        .more-btn {
          background-color: #f2e1ce;
          border-width: 0;
          padding: 6px 10px;
          border-radius: 5px;
          margin-left: 10px;
          color: #54634b;
        }
        .r-perc {
          background-color: #f2e1ce;
          border-width: 0;
          padding: 6px 10px;
          border-radius: 5px;
          margin-left: 10px;
          color: #54634b;
          span {
            margin-left: 55px;
          }
        }
      }
    }
    .energy-val-div {
      margin-top: 20px;
      border-bottom: 2px solid #ebeae4;
      padding-bottom: 20px;
      &:last-child {
        border-bottom: 0;
      }
      h5 {
        font-size: 14px;
        font-weight: 800;
      }
      .cal-btn {
        width: 48%;
        background-color: #f2e1ce;
        padding: 7px 7px;
        text-align: end;
        border-width: 0px;
        color: #54634b;
        font-size: 14px;
        font-weight: 800;
        border-radius: 5px;
      }
      .kj-btn {
        margin-left: 18px;
      }
    }
    .external-val-div {
      .confirm-btn {
        background-color: #54634b;
        width: 100%;
        border-radius: 10px;
        padding: 12px 0px;
        border-width: 0px;
        margin-top: 38px;
        color: #fff;
        font-size: 16px;
        font-weight: 800;
      }
      .external-main {
        display: flex;
        justify-content: space-between;
        .external-left {
          width: 48%;
          h5 {
            font-size: 14px;
            font-weight: 800;
          }
          button {
            width: 100%;
            background-color: #f2e1ce;
            padding: 7px 7px;
            text-align: end;
            border-width: 0px;
            color: #54634b;
            font-size: 14px;
            font-weight: 800;
            border-radius: 5px;
          }
        }
        .external-right {
          width: 48%;
          h5 {
            font-size: 14px;
            font-weight: 800;
          }
          button {
            width: 100%;
            background-color: #f2e1ce;
            padding: 7px 7px;
            text-align: end;
            border-width: 0px;
            color: #54634b;
            font-size: 14px;
            font-weight: 800;
            border-radius: 5px;
          }
        }
      }
    }
  }
  .active {
    border: $primary;
  }

  .nav-tab-div {
    border: 1px solid $secondry;
    border-radius: 8px;
    background-color: white;
    cursor: pointer;

    .nav-link {
      border: none;
      color: $primary;
      border-bottom: 1px solid transparent;
      font-weight: 800;
    }

    .nav-link.active {
      border-color: $primary;
      border-bottom-width: 4px;
    }
  }
}
