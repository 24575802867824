/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 22, 2024 */

@font-face {
  font-family: "avenirblack";
  src:
    url("avenir_black-webfont.woff2") format("woff2"),
    url("avenir_black-webfont.woff") format("woff"),
    url("avenir_black-webfont.svg#avenirblack") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "avenirlight";
  src:
    url("avenir_light-webfont.woff2") format("woff2"),
    url("avenir_light-webfont.woff") format("woff"),
    url("avenir_light-webfont.svg#avenirlight") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "avenir_regularregular";
  src:
    url("avenir_regular-webfont.woff2") format("woff2"),
    url("avenir_regular-webfont.woff") format("woff"),
    url("avenir_regular-webfont.svg#avenir_regularregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "avenirblack";
  src:
    url("avenir_black-webfont.woff2") format("woff2"),
    url("avenir_black-webfont.woff") format("woff"),
    url("avenir_black-webfont.svg#avenirblack") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "avenirlight";
  src:
    url("avenir_light-webfont.woff2") format("woff2"),
    url("avenir_light-webfont.woff") format("woff"),
    url("avenir_light-webfont.svg#avenirlight") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "avenir_regularregular";
  src:
    url("avenir_regular-webfont.woff2") format("woff2"),
    url("avenir_regular-webfont.woff") format("woff"),
    url("avenir_regular-webfont.svg#avenir_regularregular") format("svg");
  font-weight: normal;
  font-style: normal;
}
