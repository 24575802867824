/* sidebar  start*/

/*sidebar end*/
.form-check-input[type="radio"] {
  border-width: 2px;
  border-color: #352329;
}
.form-check-input:checked[type="radio"] {
  background-color: #54634b;
  outline: none;
  border-color: #54634b;
}
.form-check .form-check-label {
  font-weight: 800;
  color: #54634b;
}
.form-check .form-check-input:checked + .form-check-label {
  color: #54634b;
}
.form-switch .form-check-input {
  height: 24px;
  width: 48px;
}
.form-switch .form-check-input:focus {
  border-color: #54634b;
  outline: 0;
  box-shadow: #54634b;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(0,0,0,0.25)'/></svg>");
}
.form-switch .form-check-input:checked {
  background-color: #54634b;
  border-color: #54634b;
  border: none;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255,255,255,1.0)'/></svg>");
}

.form-check-input:checked {
  background-color: #54634b !important;
  border: 0;
}
.form-check-input:focus,
.label::after,
label.form-check-label:focus,
.form-check-input::after,
.form-check-input:not(:disabled):not(.disabled):active:focus {
  color: black;
  outline: 0;
  border: 0;
  box-shadow: 0 0 0 0.1rem #54634b !important;
}

.form-control:focus {
  border-color: #54634b;
  box-shadow: none !important;
}
.form-select:focus {
  border-color: #54634b;
  box-shadow: none !important;
}

/* form  end*/

/* login_page start*/

.login_btn {
  cursor: pointer;
  background-color: #54634b;
  width: 100%;
  color: white;
}
.login_page_bg {
  background-color: #54634b;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/* 
.login_page_logo {
  width: 100%;
  justify-content: center;
  display: flex;
} */

/* login page  end*/

/* header  start*/
.profile_name {
  height: 46px;
  width: 46px;
  background-color: #fcf9f5;
  color: #54634b;
  border-radius: 50%;
  text-align: center;
  font-size: clamp(16px, 4vw, 20px);
  line-height: 46px;
  font-weight: 800;
}

.header_dropdown {
  border: none;
  width: 200px;
  background-color: inherit !important;
  padding: 0;
}

.header_dropdown_bottom {
  border-bottom: 1px solid black;
}

.header_serchbar {
  background-color: white !important;
}

/* header end*/

/* new product start */
.new_product_media_outer {
  border: dashed rgba(53, 35, 41, 0.3) 1px;
  /* height: 232px; */
  /* width: 374px; */
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: "#F2E1C";
}
.new_product_media_inner {
  border: dashed rgba(53, 35, 41, 0.3) 1px;
  width: 100%;
  min-height: 150px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(242, 225, 206, 0.5);
}
.new_product_media_content {
  text-align: center;
}
.upload_img_text {
  font-weight: 400;
  font-size: 10px;
  line-height: 13.9px;
  color: rgba(53, 35, 41, 1);
}
.upload_icon {
  color: #54634b;
  font-size: x-large;
}
/* new product end*/

/* new recipes start */

.ingredient_bg {
  background-color: #f2e1ce33;
}

.remove_icon {
  color: rgba(152, 57, 57, 1);
}

/* new recipes end */

/* new category start */
.new_category_banner_inner {
  border: dashed rgba(53, 35, 41, 0.3) 1px;
  height: 168px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(242, 225, 206, 0.5);
}
.new_category_banner_content {
  text-align: center;
}
.upload_img_text {
  font-weight: 400;
  font-size: 9px;
  line-height: 13.9px;
  color: rgba(53, 35, 41, 1);
}
.upload_icon {
  color: #54634b;
  font-size: x-large;
}

/* new category end */

/* new coupon start */
.select_categories {
  display: flex !important;
  justify-content: right;
}

.select_products {
  display: flex !important;
  justify-content: right;
}

/* order details */

/* order details */
