.table-wrapper {
  margin-top: 58px;
  tr th {
    color: var(--primary-color);
    padding: 0px 0px 20px;
    font-size: 16px;
    width: 5%;
  }
  tr td {
    padding: 20px 0px 20px;
  }
}
.search-filter input {
  background-color: rgba(242, 225, 206, 0.2);
}
.new-item-container {
  background-color: transparent !important;
  .top-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .filter_wrap {
    width: 100%;
    max-width: 100%;
  }
  .new-item-img-container {
    margin-bottom: 16px;
    .img-text {
      font-size: 17px;
      font-weight: 800;
      color: #54634b;
    }
    .img-file-text {
      font-size: 15px;
      font-weight: 400;
      color: #54634b;
    }
  }
  .browse-filetext {
    font-size: 15px;
    color: #87a376;
    font-weight: 400;
  }
}
.label-span {
  font-size: 13px;
  color: var(--primary-color);
  font-weight: 400;
}
.cus-item-label {
  font-size: 25px;
}
.add-modifiler-group-text {
  font-size: 16px;
  color: #54634b;
  font-weight: 800;
  margin-right: 35px;
  text-decoration: none;
}
.expend-all {
  color: #87a376;
  font-size: 12px;
  margin-right: 35px;
}
.accordion-container {
  margin-top: 20px;
  .right-section {
    background-color: #ebeae4;
    border-radius: 10px;
    margin-left: 15px;
    width: 100%;

    .accordion-header {
      padding: 20px;
      background-color: #847f6329;
      border-radius: 10px 10px 0px 0px;
      p {
        margin: 0px;
        padding: 0px;
      }
      .choose-base {
        margin-left: 15px;
        color: #87a376;
        font-weight: 800;
      }
      .menu-img img {
        color: #87a376;
      }
      .customer-select-text {
        font-size: 15px;
        color: #54634b;
        font-weight: 800;
        margin-right: 15px;
      }
    }
    .accordion-body {
      padding: 20px;
      .body-data {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: white;
        padding: 20px;
        border-bottom: 1px solid #ebeae4;
        font-size: 13px;
        color: #54634b;
        p {
          margin: 0px;
          padding: 0px;
        }
      }
    }
  }
}
