.form-shimmer-container {
  display: flex;
  flex-wrap: wrap;
}

.form-shimmer-cell {
  flex: 0 0 calc(100% - 20px);
  height: 110px;
  margin: 10px;
  background: linear-gradient(to right, #f6f7f8 0%, #e8e8e8 50%, #f6f7f8 100%);
  background-size: 200% auto;
  animation: shimmer 1.5s infinite linear;
  border-radius: 10px;
}

.form-shimmer-content {
  padding: 20px;
}

.form-shimmer-title {
  height: 20px;
  width: 70%;
  margin-bottom: 10px;
}

.form-shimmer-description {
  height: 12px;
  width: 90%;
}

@keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}
