@media screen and (max-width: 1366px) {
  .topNav {
    nav {
      padding: 10px 5px;
      .searchWrap {
        svg {
          width: 18px;
        }
        input {
          font-size: 18px;
          padding: 6px 20px 6px 40px;
        }
      }
      .header_dropdown_bottom {
        .form-select {
          padding: 8px 20px 0px 6px;
        }
      }
      .profile_name {
        height: 40px;
        width: 40px;
        line-height: 40px;
      }
    }
  }
  .form-top-sticky {
    top: 80px;
  }
  .h5,
  h5 {
    font-size: 18px;
  }
  .h4,
  h4 {
    font-size: 1.3rem;
  }
  p {
    font-size: 14px;
  }
  .customer_details_table {
    .user_details {
      .prfile {
        width: 55px;
        height: 55px;
      }
    }
  }
  .btn-outline-success {
    padding: 12px 20px;
  }
}
@media screen and (max-width: 1199px) {
  .sidebar_menu {
    height: calc(100vh - 150px);
    margin-bottom: 50px;
  }
  .sidebar_close {
    width: 75px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1030;
    & + .fix-content {
      width: calc(100% - 75px);
      margin-left: 75px;
    }
  }
  .sidebar_open {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1030;
    & + .fix-content {
      width: calc(100% - 75px);
      margin-left: 75px;
    }
  }
}

@media screen and (max-width: 1023px) {
}

@media screen and (max-width: 991px) {
  .topNav {
    nav {
      background: #f2e1ce;
      padding: 10px 5px;
    }
  }
  .h4,
  h4 {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 767px) {
  .h4,
  h4 {
    font-size: 1.1rem;
  }
  .login_page_card {
    padding: 60px 40px;

    img {
      width: 130px;
    }
  }

  .logo-mobile-wrapper {
    max-width: 100px;
    svg {
      width: 100%;
    }
  }
  .header_dropdown {
    max-width: 100px;
    width: 100%;
    padding: 10px 30px 10px 0;
  }
  .fix-content {
    padding-left: calc(1.5rem * 0.5);
  }
  .sidebar_close {
    transform: translateX(-100%);
    & + .fix-content {
      width: 100%;
      margin-left: 0px;
    }
  }
  .sidebar_open {
    transform: translateX(0);
    & + .fix-content {
      width: 100%;
      margin-left: 0px;
    }
  }
  .customer_details_table {
    .filter_customer {
      form {
        max-width: 100%;
        .filter_wrap {
          svg {
            width: 18px;
          }
          input {
            padding: 8px 10px 8px 40px;
          }
        }
      }
      .form-select {
        padding: 8px 15px;
      }
    }
    .user_details {
      .user_details_section {
        border-right: 0;
        border-bottom: 1px solid rgb(210, 210, 210);
      }
    }
  }

  .order_details_heading {
    font-family: 22px;
  }
  .btn-outline-danger {
    padding: 8px 10px;
  }
}

@media screen and (max-width: 575px) {
  .topNav {
    nav {
      .logo-mobile-wrapper {
        max-width: 80px;
      }
      .profile_name {
        height: 30px;
        width: 30px;
        line-height: 30px;
      }
    }
  }
  .btn-outline-success {
    padding: 5px 10px;
    font-size: 14px;
  }
  .btn-outline-primary {
    font-size: 14px;
    min-width: auto;
    padding: 5px 10px;
  }
  .pagination {
    .page-item {
      font-size: 14px;
    }
    &.left-side {
      .form-control {
        padding: 5px;
        text-align: center;
        min-width: 40px;
      }
      .page-item {
        font-size: 14px;
        &:last-child {
          display: none;
        }
      }
    }
  }
}
